<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <div class="specialties-chip-container text-left">
          <div
            v-for="specialty in specialties"
            :key="specialty"
            class="specialty-chip d-inline"
          >
            <v-chip
              x-small
              color="primary-surface"
              label
              text-color="grey darken-3"
              class="text text-l-small-regular specialty-chip-item px-2 mb-1"
              style="margin-top:5px;"
            >
              {{ specialty }}
            </v-chip>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>

export default {
  name: "SpecialtiesComponent",
  props: ['specialties'],
}
</script>

<style scoped>
.specialty-chip {
  padding-right: 10px;
}
.specialty-chip-item {
  height: 22px !important;
}
.specialties-chip-container {
  margin: 0px 0px;
  max-height: 100%;
  transition: max-height 500ms, transform 2s;
  overflow: hidden;
}
.specialtiesicon {
  margin-right: 3px!important;
}
</style>
