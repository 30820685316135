<template>
  <div class="container ma-0 ma-lg-5 px-5">
    <v-row class="d-none d-lg-flex">
      <v-col class="col-7 d-flex align-center pb-0 pb-lg-2">
        <h1 class="heading title">
          Transcripts
        </h1>
      </v-col>
      <v-col class="col-2">
        <v-select
          v-model="selectedYear"
          :items="yearsToFilterTranscripts"
          label="Year"
          solo
          hide-details
          class="select-year"
          @change="reloadTranscripts"
        >
          <template #prepend-inner>
            <span class="select-muted-text">Show: </span>
          </template>
        </v-select>
        <div
          class="btn-download mt-5 mr-2 text-right"
          @click="downloadPdf"
        >
          <v-icon>mdi-download</v-icon>
          donwload transcripts
        </div>
      </v-col>
    </v-row>
    <v-row class="view-container mt-0 mt-lg-6">
      <v-col class="col-12 col-lg-9">
        <VueHtml2pdf
          ref="pdfWrapper"
          :show-layout="true"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="false"
          :filename="'file'"
          :pdf-quality="2"
          :pdf-format="'a4'"
          :pdf-orientation="'portrait'"
          :manual-pagination="true"
          :html-to-pdf-options=" {
            margin: 12, pagebreak: { mode: 'avoid-all'} }"
        >
          <div slot="pdf-content">
            <v-row
              no-gutters
              class="d-none container-margin-top container-margin-bottom"
            >
              <h1 class="heading title">
                Total credits
              </h1>
            </v-row>
            <v-row
              no-gutters
              class="d-none"
              :class="{'container-margin-left' : hideElementForPDF }"
            >
              <v-col class="col-12 col-lg-auto container-margin-right">
                <ProcessedBadgeIconComponent
                  :width="'40'"
                  :height="'44'"
                />
              </v-col>
              <v-col class="col-12 col-lg-auto">
                <h2>{{ currentUser.awardedCredits.total }} CME</h2>
                <div class="credits-muted-text">
                  Processed credits
                </div>
              </v-col>
            </v-row>
            <div
              class="d-block d-lg-none pb-3"
              :class="{'container-margin-top-2 container-margin-bottom' : hideElementForPDF }"
            >
              <h1 class="heading title">
                Transcripts
              </h1>
            </div>
            <TranscriptComponent
              v-for="transcript,index in transcripts"
              :key="index+uniqueKeysHelper()"
              :transcript="transcript"
              :hide-on-download-pdf="hideElementForPDF"
              class="my-3"
            />
          </div>
        </VueHtml2pdf>
      </v-col>
      <v-col class="col-12 col-lg-3 pl-lg-5 pr-lg-15">
        <div class="pb-8 d-lg-none">
          <v-row no-gutters>
            <v-col class="col-6">
              <h1 class="heading title">
                Credits
              </h1>
            </v-col>
            <v-col class="col-6">
              <v-select
                v-model="selectedYear"
                :items="yearsToFilterTranscripts"
                label="Year"
                solo
                hide-details
                class="select-year"
                @change="reloadTranscripts"
              >
                <template #prepend-inner>
                  <span class="select-muted-text">Show: </span>
                </template>
              </v-select>
              <div
                class="btn-download mt-5 mr-2 text-right"
                @click="downloadPdf"
              >
                <v-icon>mdi-download</v-icon>
                donwload transcripts
              </div>
            </v-col>
          </v-row>
        </div>
        <v-card class="card-box mt-3 mt-lg-6 pl-7 pr-5">
          <v-row>
            <v-col class="col-6 col-lg-12 pt-5">
              <v-row no-gutters>
                <v-col class="col-12 col-lg-auto">
                  <ProcessedBadgeIconComponent
                    :width="badgesWidth"
                    :height="badgesHeight"
                  />
                </v-col>
                <v-col class="col-12 col-lg-auto pl-lg-3">
                  <div class="heading credits-text">
                    {{ creditLimit }} CME
                  </div>
                  <div class="credits-muted-text mb-1">
                    Credit limit
                  </div>
                  <v-progress-linear
                    :value="currentUser.credit_limit"
                    background-color="#C5CEDD"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              class="d-none d-lg-block"
            >
              <v-divider />
            </v-col>
            <v-col class="col-6 col-lg-12 pt-5 pl-5 pl-lg-2">
              <v-row no-gutters>
                <v-col class="col-12 col-lg-auto">
                  <PendingBadgeIconComponent
                    :width="badgesWidth"
                    :height="badgesHeight"
                  />
                </v-col>
                <v-col class="col-12 col-lg-auto pl-lg-3">
                  <div class="heading credits-text">
                    {{ awardedCredits }} CME
                  </div>
                  <div class="credits-muted-text mb-1">
                    Total credits
                  </div>
                  <v-btn
                    disabled
                    ripple
                    depressed
                    color="primary"
                    class="rounded-lg btn-quiz mt-2 d-none"
                  >
                    Send credits
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TranscriptComponent from "@/components/transcripts/TranscriptComponent";
import ProcessedBadgeIconComponent from "@/assets/ProcessedBadgeIconComponent";
import PendingBadgeIconComponent from "@/assets/PendingBadgeIconComponent";
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
const VueHtml2pdf = () => import('vue-html2pdf');

export default {
  components: { TranscriptComponent, ProcessedBadgeIconComponent, PendingBadgeIconComponent, VueHtml2pdf },
  data: () => ({
    selectedYear: '2021',
    yearsToFilterTranscripts: ['2021', '2020'],
    transcripts: null,
    uniqueKeysHelper: uuidv4,
    hideElementForPDF: false,
  }),
  computed: {
    badgesWidth () {
      return this.$vuetify.breakpoint.width > 960 ? 58 : 29;
    },
    badgesHeight () {
      return this.$vuetify.breakpoint.width > 960 ? 64 : 32;
    },
    creditLimit() {
      return (this.currentUser?.credit_limit/1).toFixed(1);
    },
    awardedCredits() {
      return (this.currentUser?.awardedCredits.total/1).toFixed(1);
    },
    ...mapGetters('auth', ['currentUser']),
  },
  methods: {
    ...mapActions('transcripts', ['getTranscripts']),
    ...mapActions('menu', ['hideTopMenu', 'setTopMenuTitle']),
    reloadTranscripts() {
      this.getTranscripts({ params: { year: this.selectedYear }}).then( response => {
        this.transcripts = response.data;
      }).catch( error => {
        this.$toasted.global.showError({message:  error.response.data ?  error.response.data.message : 'Unexpected error'});
      });
    },
    async downloadPdf() {
      this.hideElementForPDF = true;
      await this.$refs.pdfWrapper.generatePdf();
      this.hideElementForPDF = false;
    },
  },
  async mounted() {

    this.setTopMenuTitle('Transcripts');
    this.hideTopMenu(true);

    this.reloadTranscripts();
  }
}
</script>

<style scoped>
div.v-card.card-box {
  box-shadow: none;
  border-radius: 10px;
}
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.1px;
  color: #171725;
}
.view-container {
  display: flex;
  flex-direction: column-reverse;
}
.credits-muted-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #696974;
}
.select-muted-text {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;
  color: #696974;
}
.credits-text {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #171725;
}
.btn-quiz {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 32px;
  width: 122px;
  height: 23px !important;
  letter-spacing: 2px;
}
.btn-download {
  color: #78909C;
  font-size: 10px;
  height: 23px !important;
}
.btn-download:hover {
  cursor: pointer;
}
.select-year {
  border-radius: 10px;
  height: 32px;
  width: 130px;
  margin-left: auto;
}
div.v-input.v-select.select-year >>> div.v-input__control div.v-input__slot {
  box-shadow: none;
}
div.v-input.v-select.select-year >>> div.v-select__slot label.v-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;
}
div.v-input.v-select.select-year >>> div.v-select__slot div.v-select__selections div.v-select__selection {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;
  color: #44444F;
}
@media (min-width: 960px) {
  .view-container {
    display: flex;
    flex-direction: row;
  }
  .btn-quiz {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 32px;
    width: 135px;
    height: 28px !important;
    letter-spacing: 2px;
  }
  .select-year {
    height: 38px;
    width: 155px;
  }
  .select-muted-text {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #696974;
  }
  div.v-input.v-select.select-year >>> div.v-select__slot label.v-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
  }
  div.v-input.v-select.select-year >>> div.v-select__slot div.v-select__selections div.v-select__selection {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #44444F;
  }
}
.container-margin-top {
  margin-top: 10px;
}
.container-margin-top-2 {
  margin-top: 15px;
}
.container-margin-right {
  margin-right: 12px;
}
.container-margin-left {
  margin-left: 12px;
}
.container-margin-bottom {
  margin-bottom: 20px;
}
</style>
<style>
.content-wrapper {
  width: auto !important;
}
div.specialties-chip-container span.v-chip__content, h5.abstract-title {
  font-style: normal;
}
</style>
