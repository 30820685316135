<template>
  <v-card
    class="card-box"
    :class="{ 'card-box-pdf' : !hidePDFelement }"
  >
    <v-row
      no-gutters
      :class="{'container-margin-left' : !hidePDFelement }"
    >
      <v-col class="col-12 col-lg-2 py-4">
        <v-row
          no-gutters
          class="px-3"
          justify-lg="center"
        >
          <v-col class="col-6 col-lg-12 container-flex align-center justify-lg-center">
            <div class="container-padding-right">
              <ProcessedBadgeIconComponent
                v-show="transcript.status == 'processed'"
                :width="badgesWidth"
                :height="badgesHeight"
              />
              <PendingBadgeIconComponent
                v-show="transcript.status == 'awarded' || transcript.status == 'unawarded' "
                :width="badgesWidth"
                :height="badgesHeight"
              />
            </div>
            <div class="credits-box">
              <div class="heading credit-number">
                <span
                  :class="{'credit-number-state-awarded': transcript.status == 'awarded',
                           'credit-number-state-processed': transcript.status == 'processed'}"
                >
                  {{ transcript.earned_credits }}
                </span>
              </div>
              <div class="credit-unit">
                <span>CME</span>
              </div>
            </div>
          </v-col>
          <v-col
            class="col-6 col-lg-12 text-right text-lg-center"
            :class="{'container-margin-top' : !hidePDFelement }"
          >
            <v-chip
              class="ma-2 px-4 theme-default-border "
              :class="{'theme-custom-v-chip-error': transcript.status == 'rejected',
                       'theme-custom-v-chip-warning': transcript.status == 'unawarded',
                       'theme-custom-v-chip-success': transcript.status == 'awarded'}"
              label
              text-color="black"
            >
              <v-icon
                left
                :class="{'red--text': transcript.status == 'rejected', 'red--text': transcript.status == 'unawarded',
                         'icon-green': transcript.status == 'awarded'}"
              >
                {{ transcript.status == 'awarded' ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline' }}
              </v-icon>
              <span
                class="text-capitalize-first-letter"
                :class="{ 'status-label-size-for-pdf' : !hidePDFelement }"
              >{{ getStatusLabel }}</span>
            </v-chip>
          </v-col>
          <v-col
            v-show="hidePDFelement"
            class="col-6 col-lg-12 text-lg-center"
          />
          <v-col
            cols="12"
            class="d-lg-none pt-3"
          >
            <v-divider />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-12 col-lg-8 py-0 py-lg-3">
        <v-row no-gutters>
          <v-col class="col-6 container-flex align-center">
            <h3 class="heading d-none d-lg-block pr-2 pl-3 subtitle-section">
              Activity ID
            </h3><h3
              v-show="hidePDFelement"
              class="heading d-block d-lg-none pr-2 pl-3 subtitle-section"
            >
              ID
            </h3>
            <span class="heading activity-number"> {{ transcript.id }}</span>
          </v-col>
          <v-col class="col-6 container-flex container-text-right align-center container-flex-justify-end">
            <span class="heading activity-date pr-3">{{ getCleanDate }}</span>
          </v-col>
          <v-col class="col-12 my-3">
            <h5
              class="abstract-title px-3"
              :class="{'container-margin-top' : !hidePDFelement }"
            >
              {{ transcript.title }}
            </h5>
          </v-col>
          <v-col
            v-show="hidePDFelement"
            class="col-12 d-lg-none"
          >
            <v-row
              no-gutters
              class="show-more-button-container"
            >
              <v-col class="col-12 text-center">
                <v-icon>{{ getPanelIcon }}</v-icon>
                <span
                  class="show-more-button-text"
                  @click="toggleShowMorePanel"
                >{{ getPanelText }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          v-show="showPanel || !hidePDFelement"
          no-gutters
          class="px-3 mt-lg-5"
        >
          <v-col class="col-12">
            <h3
              class="heading subtitle-section mt-2"
              :class="{'container-margin-top' : !hidePDFelement }"
            >
              Credits
            </h3>
          </v-col>
          <v-col class="col-12">
            <SpecialtiesComponent :keywords="transcript.specialties_credits" />
          </v-col>
          <v-col
            cols="12"
            class="d-lg-none pt-3"
          >
            <v-divider />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-show="showPanel"
        class="col-12 col-lg-2 py-0 py-lg-3 mx-lg-0 pb-3 justify-space-between align-center credits-container"
      >
        <div class="container-padding-x">
          <h3 class="heading subtitle-section mt-2 mt-lg-0 mb-lg-2">
            Quiz
          </h3>
          <div
            class="quiz-muted-text"
            :class="{'container-margin-top' : !hidePDFelement }"
          >
            First try: 50%
          </div>
          <div class="quiz-muted-text">
            Final result: {{ transcript.score }}%
          </div>
        </div>
        <div class="text-right text-lg-center">
          <div
            v-show="!userClaimedCredits && hidePDFelement"
            class="quiz-container-red-warning mt-3 pb-3 px-3"
          >
            The CME for this quiz has not been awarded, because the limit has been reached.
          </div>
          <v-btn
            v-show="hidePDFelement"
            ripple
            depressed
            outlined
            color="primary"
            class="rounded-lg text-capitalize-first-letter btn-quiz mr-3 mr-lg-0 mt-lg-3"
            @click="seeQuiz"
          >
            See quiz
          </v-btn>
        </div>
      </v-col>
      <v-col class="d-none col-12">
        <div
          v-show="!userClaimedCredits && !hidePDFelement"
          class="quiz-container-red-warning mt-3 pb-3 px-3"
          :class="{'red-warning-width-auto' : !hidePDFelement}"
        >
          The CME for this quiz has not been awarded, because the limit has been reached.
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import SpecialtiesComponent from "@/components/common/SpecialtiesComponent";
import ProcessedBadgeIconComponent from "@/assets/ProcessedBadgeIconComponent";
import PendingBadgeIconComponent from "@/assets/PendingBadgeIconComponent";
export default {
  components: { SpecialtiesComponent, ProcessedBadgeIconComponent, PendingBadgeIconComponent },
  props: ['transcript', 'hideOnDownloadPdf'],
  data: () => ({
    status: 'awarded',
    isShowMorePanelOpen: false,
    isShowingMobileElements: false,
    userClaimedCredits: false,
  }),
  computed: {
    badgesWidth () {
      return this.$vuetify.breakpoint.width > 960 ? 40 : 29;
    },
    badgesHeight () {
      return this.$vuetify.breakpoint.width > 960 ? 44 : 32;
    },
    getStatusLabel() {
      return this.transcript.status;
    },
    getCleanDate() {
      let dateFormat = new Date(this.transcript.created_at);

      return dateFormat.getMonth()+'/'+ dateFormat.getDate()+'/'+dateFormat.getFullYear();
    },
    getPanelIcon() {
      return (this.isShowMorePanelOpen ? 'mdi-chevron-up' : 'mdi-chevron-down');
    },
    getPanelText() {
      return (this.isShowMorePanelOpen ? 'Less info' : 'Show more');
    },
    showPanel() {
      return this.$vuetify.breakpoint.width > 960 || this.isShowMorePanelOpen;
    },
    hidePDFelement() {
      return !this.hideOnDownloadPdf;
    },
    showMobileElements() {
      return this.isShowingMobileElements;
    }
  },
  mounted() {
    this.userClaimedCredits = this.transcript.claimed_date != null;
  },
  methods: {
    toggleShowMorePanel() {
      this.isShowMorePanelOpen = !this.isShowMorePanelOpen;
    },
    seeQuiz() {
      this.$router.push({ name: "transcripts-results", params: { id: this.transcript.quiz_id }});
    },
  }
}
</script>

<style scoped>
.container-flex {
  display: flex;
}
.container-flex-justify-end {
  justify-content: flex-end;
}
.container-text-right {
  text-align: right !important;
}
.container-padding-x {
  padding-left: 12px;
  padding-right: 12px;
}
.container-padding-right {
  padding-right: 12px;
}
.container-margin-top {
  margin-top: 10px;
}
.container-margin-top-2 {
  margin-top: 15px;
}
.container-margin-right {
  margin-right: 12px;
}
.container-margin-left {
  margin-left: 12px;
}
.pdf-title {
  margin-left: 10px;
  margin-bottom: 10px;
}
div.v-card.card-box {
  box-shadow: none;
  border-radius: 10px;
}
.credits-box {
  line-height: 1.2;
}
.credit-number {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.116667px;
}
.credit-number-state-processed {
  color: #1DA1F2;
}
.credit-number-state-awarded {
  color: #6563FF;
}
.credit-unit {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #92929D;
}
.subtitle-section {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #171725;
  margin-right: 6px !important;
}
.activity-number {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #171725;
}
.activity-date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.5px;
  color: #171725;
}
h5.abstract-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #44444F;
}
.v-chip.theme-default-border {
  border-radius: 10px !important;
}
.v-chip.theme-custom-v-chip-error {
  background-color: #FED4D4 !important;
  border-color: #FED4D4 !important;
}
.v-chip.theme-custom-v-chip-success {
  background-color: #DCFFE4 !important;
  border-color: #DCFFE4 !important;
}
.v-chip.theme-custom-v-chip-warning {
  background-color: #FDF7D7 !important;
  border-color: #FDF7D7 !important;
}
.v-icon.icon-orange {
  color: #F2C94C !important;
}
.v-icon.icon-red {
  color: #FC5A5A !important;
}
.v-icon.icon-green {
  color: #3DD598 !important;
}
.v-divider {
  border-color: #F1F1F5;
}
.show-more-button-container {
  background-color: #F1F1F5;
  border-radius: 0px 0px 10px 10px;
}
.show-more-button-text {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  text-align: center;
  color: #78909C;
}
.show-more-button-text:hover {
  cursor: pointer;
}
.btn-quiz {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 23px;
  height: 23px !important;
  width: 90px;
}
.credits-container {
  display: flex;
  flex-direction: row;
}
@media (min-width: 960px) {
  div.v-card.card-box {
    height: 215px !important;
  }
  .btn-quiz {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 36px;
    height: 36px !important;
  }
  .credits-container {
    height: 189px !important;
    display: flex;
    flex-direction: column;
  }
  .credit-unit {
    font-size: 14px;
    line-height: 16px;
  }
  .credit-number {
    font-size: 25px;
    line-height: 33px;
  }
  h5.abstract-title {
    font-size: 14px;
    line-height: 24px;
  }
  .activity-number {
    font-size: 16px;
  }
  .activity-date {
    font-size: 16px;
  }
  .subtitle-section {
    font-size: 16px;
  }
}
.quiz-muted-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #92929D;
}
.quiz-container-red-warning {
  width: 160px;
  text-align: justify;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #FC5A5A;
}
.red-warning-width-auto { 
  width: auto;
}
div.v-card.card-box-pdf {
  height: 100% !important;
  border: 1px solid rgb(47, 47, 47);
  padding: 9px;
  margin-top: 15px;
}
.text-capitalize-first-letter {
  text-transform: capitalize;
}
.status-label-size-for-pdf {
  font-size: 12px;
}
</style>
